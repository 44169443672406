.sellerOverviewTable {
    width: 100%;
}
.sellerOverviewTable thead{
 color: #000;
}

.sellerOverviewTable tbody tr:nth-child(even){
    /* background-color: rgb(208, 209, 209) !important; */
    background-color: #F7F7F7;
}

.sellerOverviewTable .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover{
    background-color: white ;
}


/* .sellerOverviewTable tbody td{
    font-size: 14px;
    font-weight: 520px;
    font-family: 'poppins !important';
} */


.sellerOverviewTable thead th{
    font-size: 14px !important;
    font-weight: 520px !important;
    font-family: 'poppins';
    color: rgb(0, 0, 0);
    /* background-color: #514943;  */
    background-color: #FFFFFF; 
}